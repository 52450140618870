import React, { Component } from 'react';

// vendor components
import Helmet from 'react-helmet';

const termlyPrivacyPolicyId = process.env.GATSBY_TERMLY_PRIVACY_POLICY_ID;

class PrivacyPolicy extends Component {
  render() {
    const iframeSrc = `https://app.termly.io/document/privacy-policy/${termlyPrivacyPolicyId}`;

    return (
      <>
        <iframe
          width="100%"
          style={{ height: '95vh' }}
          title="Privacy Policy"
          src={iframeSrc}
          frameBorder="0"
          allowFullScreen
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
        <Helmet title="Privacy Policy" />
      </>
    );
  }
}

export default PrivacyPolicy;
